body {
    padding: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu',
        'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

section {
    margin: 10px 10px;
    background-color: #fafafa;
    box-shadow: #f4f4f4 0px 2px 4px 0px, #f3f3f3 0px 2px 16px 0px;
}

article {
    border-radius: 4px;
    background-color: #fafafa;
    box-shadow: #f4f4f4 0px 2px 4px 0px, #f3f3f3 0px 2px 16px 0px;
}

#thumbnail {
    margin: 0;
    min-width: 22%;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 4px;

    img {
        padding: 10% 0;
        max-width: 120px;
        max-height: 120px;
    }

    .preview {
        padding: 15% 0;
    }
}
